import { useSearchBox } from "react-instantsearch";
import React, {useRef, useState, useEffect } from "react";
import { debounce } from "lodash";

import { TextInput, ActionIcon } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';

import { logSearchQuery } from "./analytics";

function CustomSearchBox(props) {
    const { query, refine } = useSearchBox(props);
    const [inputValue, setInputValue] = useState(query);
    const inputRef = useRef(null);

    function setQuery(newQuery) {
        setInputValue(newQuery);

        refine(newQuery);
    }

    const clearInput = () => {
        setQuery('');
        inputRef.current?.focus();
    };

    useEffect(() => {
        const debouncedLogSearchQuery = debounce((query) => {
            logSearchQuery(query);
        }, 1500);

        if (inputValue) {
            debouncedLogSearchQuery(inputValue);
        }

        // Cleanup function to clear the timeout if the component unmounts or inputValue changes
        return () => {
            debouncedLogSearchQuery.cancel();
        };
    }, [inputValue]);

    return (
        <TextInput
            ref={inputRef}
            value={inputValue}
            placeholder="Typ iets..."
            my="lg"
            radius="lg"
            style={{ width: '100%', maxWidth: '500px', minWidth: '300px'}}
            onChange={(event) => setQuery(event.currentTarget.value)}
            rightSection={
                inputValue ? (
                    <ActionIcon radius="lg" onClick={clearInput}>
                        <IconX size={18} />
                    </ActionIcon>
                ) : (
                    <IconSearch size={18} />
                )
            }
            rightSectionWidth={42}
            styles={{
                rightSection: { pointerEvents: 'all' },
            }}
        />
    );
}

export default CustomSearchBox;