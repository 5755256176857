import ReactGA from 'react-ga4';

export const initializeAnalytics = () => {
    const measurementId = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;

    if (measurementId && measurementId !== "") {
        ReactGA.initialize(measurementId);
        console.log("Google Analytics initialized.");
    } else {
        console.log("Google Analytics not initialized: Measurement ID is not set or is empty.");
    }
};

export const logSearchQuery = (query) => {
    const measurementId = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;

    if (measurementId && measurementId !== "") {
        ReactGA.event({
            category: 'Search',
            action: 'Query',
            label: query,
        });
        console.log("Send ga4 query: " + query);
    }

    if (window.fathom) {
        window.fathom.trackEvent('SEARCH_QUERY', { _value: query });
        console.log("Fathom Analytics event sent: " + query);
    }

    const strapi_endpoint = process.env.REACT_APP_STRAPI_ENDPOINT;
    const strapi_key = process.env.REACT_APP_STRAPI_QUERY_LOG;
    if (strapi_endpoint && strapi_key) {
        sendQueryToStrapi(query, strapi_endpoint, strapi_key).then(data => console.log("Send query to strapi " + query));
    }
};

async function sendQueryToStrapi(query, strapi_endpoint, strapi_key) {
    const url = `${strapi_endpoint}/api/queries`;

    const requestBody = {
        data: {query: query}
    };

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${strapi_key}`
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
    } catch (error) {
        console.error('Error sending query to Strapi:', error);
    }
}