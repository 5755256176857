import {Anchor, Badge, Card, Group, Text} from "@mantine/core";
import React from "react";

const Hit = ({ hit }) => (
    <Card align="left" shadow="sm" padding="lg" m="lg" radius={0} withBorder style={{ minWidth: '300px' }}>
        <Text fw={700}>{hit.title}</Text>
        <Text c="dimmed">{hit.paragraph}</Text>
        <Group justify="space-between">
            <Anchor href={hit.link_to_document} target="_blank">
                {hit.name_of_document}
            </Anchor>
            <Badge radius={0} fs="italic" style={{ backgroundColor: '#062e70'}}>
                {hit.location}
            </Badge>
        </Group>
    </Card>
);

export default Hit;